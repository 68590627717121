import {useCallback, useEffect} from "react";
import SCTimePicker from "./SCTimePicker";
import {useTimeToNumber} from "../services/utils";
import {useSelector, useDispatch} from "react-redux";

export default function EditableSchedule({day, onChange, errors}) {
    const timeToNumber = useTimeToNumber()
    const currentSchedule = useSelector(s => s.currentSchedule.value)
    const dispatch = useDispatch()
    let manageSlot = useCallback((slot, nextSlot, force) => {
        if ((force || timeToNumber(currentSchedule[`${nextSlot}${day}`]) > 0) && timeToNumber(currentSchedule[`${slot}${day}`]) > timeToNumber(currentSchedule[`${nextSlot}${day}`])) {

        }
    }, [currentSchedule, day, timeToNumber])

    useEffect(() => {
            if (currentSchedule) {
                manageSlot("heureDebutPeriode1", "heureFinPeriode1", true)
                manageSlot("heureFinPeriode1", "heureDebutPeriode2", false)
                manageSlot("heureDebutPeriode2", "heureFinPeriode2", false)
            }
        }
        ,
        [currentSchedule, manageSlot]
    )


    useEffect(() => {
    }, [errors])
    return (
        <tr key={day}>
            <td className="px-6 py-4 whitespace-nowra text-right">
                <div className="flex text-right">
                    <div className="ml-4">
                        <span>{day}</span>
                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <SCTimePicker
                                field={`heureDebutPeriode1`}
                                day={day}
                            /> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}
                    </div>
                </div>
            </td>

            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <SCTimePicker field={`heureFinPeriode1`}
                                          day={day}
                            /> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}

                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <SCTimePicker
                                field={`heureDebutPeriode2`}
                                day={day}
                            /> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}

                    </div>
                </div>
            </td>
            <td className="px-6 py-4 whitespace-nowrap">
                <div className="flex items-center">
                    <div className="ml-4">
                        {currentSchedule ?
                            <SCTimePicker
                                field={`heureFinPeriode2`}
                                day={day}
                            /> :
                            <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-24 h-7 mx-2 m-1"}/>}

                    </div>
                </div>
            </td>
            <td className="px-6 py-4 white space-nowrap font-medium">
                <div className="flex items-center">
                    {currentSchedule ?
                        <input
                            className="form-tick h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                            type="checkbox"
                            checked={currentSchedule[`estFerme${day}`]}
                            onChange={(e) => {
                                let newCurrentSchedule = {...currentSchedule}
                                newCurrentSchedule[`estFerme${day}`] = e.target.checked
                                dispatch({type: "currentSchedule/setSchedule", payload: newCurrentSchedule})
                            }}
                        /> : <div className={"animate-pulse rounded bg-gray-500 w-7 h-7 mx-2 m-1"}/>}
                </div>

            </td>
        </tr>
    )
}