import {useParams} from "react-router-dom";
import EditableSchedule from "../components/EditableSchedule";
import {Fragment, useEffect, useState} from "react";
import {useAxios} from "../services/httpclient";
import GldButton from "../components/GldButton";
import {MenuIcon, RefreshIcon, SaveIcon} from "@heroicons/react/solid";
import "./ShopSchedules.css"
import {Dialog, Transition} from '@headlessui/react'
import {XIcon} from '@heroicons/react/outline'
import {
    Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel,
} from 'react-accessible-accordion';
import AccordionSchedule from "../components/AccordionSchedule";
import {toast} from "react-toastify";
import {useDateToNumber, useIsvalidTime, useTimeToNumber} from "../services/utils";
import SideBar from "../components/SideBar";
import SpecialSideBar from "../components/SpecialSideBar";
import EditableSpecialSchedule from "../components/EditableSpecialSchedule";
import {useDispatch, useSelector} from "react-redux";


export default function ShopSchedules() {
    let {codeSap} = useParams();
    const timeToNumber = useTimeToNumber()
    const dateToNumber = useDateToNumber()
    const isValidTime = useIsvalidTime()
    const axios = useAxios()
    const [open, setOpen] = useState(false)
    const [refresh, setRefresh] = useState(false)
    const [loaded, setLoaded] = useState(false)
    const [active, setActive] = useState({indexSchedule: 0, indexSubSchedule: 0, special: false})
    const [savingIncon, setSavingIcon] = useState(<SaveIcon/>)
    const [errors,] = useState({})
    const [currentErrors, setCurrentErrors] = useState({})
    const [store, setStore] = useState()
    const [actualRes, setActualRes] = useState()
    const [specialSchedules, setSpecialSchedules] = useState([])
    const [specialSchedulesConfig, setSpecialSchedulesConfig] = useState({"types": [], "causes": []})
    const [canSaveSpecialSchedule] = useState(true)

    const currentSchedule = useSelector(s => s.currentSchedule.value)
    const schedules = useSelector(s => s.schedules.value)
    const dispatch = useDispatch()


    const days = ["Lundi", "Mardi", "Mercredi", "Jeudi", "Vendredi", "Samedi", "Dimanche"]


    function checkSpecialSchedules() {
        let canSave = true
        specialSchedules.forEach((s, index) => {

            let dateDebut = s["dateDebut"].split("-").join("");
            let dateFin = s["dateFin"].split("-").join("");
            if (dateFin < dateDebut) {
                toast.error(`${s["dateDebut"]} doit être inférieur à  ${s["dateFin"]}`)
                canSave = false
            }
            for (let i = 0; i < specialSchedules.length; i++) {
                if (i !== index) {
                    let tStartB = specialSchedules[i]["dateDebut"];
                    let tEndB = specialSchedules[i]["dateFin"];
                    let overlap = dateToNumber(s["dateDebut"]) < dateToNumber(tStartB) && dateToNumber(tEndB) < dateToNumber(s["dateFin"]);
                    if (overlap) {
                        canSave = false
                        toast.error(`les périodes [${s["dateDebut"]} - ${s["dateFin"]}] et [${tStartB} - ${tEndB}] ne doivent pas se chevaucher`)
                        break
                    }
                }
            }
        })
        return canSave
    }


    useEffect(() => {

        if (schedules[active.indexSchedule] && schedules[active.indexSchedule].subSchedules[active.indexSubSchedule]) {
            let currentSchedule = schedules[active.indexSchedule].subSchedules[active.indexSubSchedule]
            dispatch({type: "currentSchedule/setSchedule", payload: currentSchedule})
        }
    }, [active, dispatch, schedules])

    useEffect(() => {

        if (actualRes && schedules[active.indexSchedule] && schedules[active.indexSchedule]?.name) {
            let currentSchedule = actualRes[schedules[active.indexSchedule].name][active.indexSubSchedule]
            dispatch({type: "currentSchedule/setSchedule", payload: currentSchedule})
            for (let day of days) {
                for (let slot of ["heureDebutPeriode1", "heureFinPeriode1", "heureDebutPeriode2", "heureFinPeriode2"]) {
                    dispatch({
                        type: "possibleTimes/onScheduleChange", payload: {
                            day: day, field: slot, schedule: currentSchedule
                        }
                    })
                }
            }

            let horairesExceptionnels = actualRes["infosHorairesExceptionnel"];
            for (let h of horairesExceptionnels) {
                if (h["cause"] == null) h["cause"] = ""; else {
                    let find = specialSchedulesConfig["causes"].find(c => c.value === h["cause"]);
                    if (!!find) {
                        h["cause"] = find.key
                    } else {
                        h["cause"] = ""
                    }
                }
                if (h["type"] == null) h["type"] = ""; else {
                    let find = specialSchedulesConfig["types"].find(c => c.value === h["type"]);
                    if (!!find) {
                        h["type"] = find.key
                    } else {
                        h["type"] = ""
                    }
                }
                if (h["dateDebut"] == null) h["dateDebut"] = ""
                if (h["dateFin"] == null) h["dateFin"] = ""
                if (h["heureDebut"] == null) h["heureDebut"] = ""
                if (h["heureFin"] == null) h["heureFin"] = ""
            }
            setSpecialSchedules(horairesExceptionnels)
            setLoaded(true)
        }
        // eslint-disable-next-line
    }, [actualRes, specialSchedulesConfig])


    function checkFields() {
        let error = null
        schedules.forEach((schedule, scheduleIndex) => {
            schedules[scheduleIndex] && schedules[scheduleIndex].subSchedules.forEach((subSchedule) => {
                for (let day of days) {
                    const start1 = subSchedule[`heureDebutPeriode1${day}`]
                    const end1 = subSchedule[`heureFinPeriode1${day}`]
                    const start2 = subSchedule[`heureDebutPeriode2${day}`]
                    const end2 = subSchedule[`heureFinPeriode2${day}`]

                    if ((start2 && !end2) || (end2 && !start2)) {
                        error = `Les deux horaires de deuxième période du ${day} pour l'horaire ${schedule.name} doivent être nuls`
                        return;
                    }
                    if ((start1 && !end1) || (!start1 && end1)) {
                        error = `Les deux horaires de première période du ${day} pour l'horaire ${schedule.name} doivent être nuls`
                        return;
                    }
                    if (timeToNumber(start1) > timeToNumber(end1)) {
                        error = `Les horaires de ${day} pour l'horaire ${schedule.name} doivent être croissants`
                        return
                    }

                    if ((start2 && end2) && (timeToNumber(end1) > timeToNumber(start2) || timeToNumber(start2) > timeToNumber(end2))) {
                        error = `Les horaires de ${day} pour l'horaire ${schedule.name} doivent être croissants`
                        return
                    }
                    for (let horaire of ['heureDebutPeriode1', 'heureFinPeriode1', 'heureDebutPeriode2', 'heureFinPeriode2']) {
                        if (subSchedule[`${horaire}${day}`] && !isValidTime(subSchedule[`${horaire}${day}`])) {
                            error = `le champ ${horaire} de ${day} pour l'horaire ${schedule.name} n'est pas valide. Les minutes doivent être un multiple de 15`
                            return;
                        }
                    }
                }
            })
        })
        return error
    }

    function doSaveSpecialSchedules() {

        if (!checkSpecialSchedules() || !canSaveSpecialSchedule) {
            toast.error("Impossible de sauvegarder les horaires exceptionnel : veuillez vérifier que toutes les dates sont correctes.")
        } else {

            !!axios.current && axios.current.post("/schedules/special", specialSchedules)
                .then(r => {
                    toast.success("Mise à jour effectuée. Veuillez patienter quelques secondes avant la prise en compte des nouvelles données.", {
                        theme: "colored", className: "bg-green-100"
                    })
                })
        }
    }

    function doSave() {
        let error = checkFields()
        if (error) {
            toast.error(error)
            return
        }
        setSavingIcon(<RefreshIcon/>)
        !!axios.current && axios.current.post('/schedules/schedules', currentSchedule)
            .then(r => {
                setSavingIcon(<SaveIcon/>)
            })
            .then(r => {
                toast.success("Mise à jour effectuée. Veuillez patienter quelques secondes avant la prise en compte des nouvelles données.", {
                    theme: "colored", className: "bg-green-100"
                })
            })
    }

    function displaySchedule(is, indexSubSchedule, sp) {
        // setCurrentSchedule(schedules[is].subSchedules[indexSubSchedule])
        setCurrentErrors(errors && errors[is] && errors[is].subSchedules[indexSubSchedule])
        setActive({indexSchedule: is, indexSubSchedule: indexSubSchedule, special: sp})
    }

    function doRefresh() {
        setRefresh(prevState => !prevState)
    }


    useEffect(() => {
        setLoaded(false)
        dispatch({type: "schedules/deleteSchedules"})
        !!axios.current && axios.current.get(`/schedules/special/causes`)
            .then(res => setSpecialSchedulesConfig(prevState => ({...prevState, "causes": res.data})))
        !!axios.current && axios.current.get(`/schedules/special/types`)
            .then(res => {
                setSpecialSchedulesConfig(prevState => ({...prevState, "types": res.data}))
            })

        !!axios.current && axios.current.get(`/pointsofsale/${codeSap}/schedule`)
            .then(res => {
                setStore(res.data)
                for (const k in res.data) {
                    if (k.match(/infos*/)) {
                        if (k === "infosHorairesExceptionnel") {
                            setSpecialSchedules(res.data[k])
                        } else {
                            dispatch({
                                type: "schedules/addSchedule", payload: {
                                    name: k, subSchedules: res.data[k]
                                }
                            })
                        }
                    }
                }
                return res
            })
            .then(res => {
                setActualRes(res.data)
            })
        // eslint-disable-next-line
    }, [axios, codeSap, refresh])

    useEffect(() => {

        if (currentSchedule && schedules[active.indexSchedule] && schedules[active.indexSchedule].subSchedules[active.indexSubSchedule]) {
            dispatch({type: "schedules/updateCurrentSchedule", payload: {active, currentSchedule}})
        }
        // eslint-disable-next-line
    }, [currentSchedule])


    return (<div className={"mt-6 py-8"}>
        <div className={"flex flex-wrap gap-x-6 gap-y-1 mb-6"}>
            <div className={"flex-grow md:w-max w-max"}>
                    <span
                        className="uppercase text-2xl">Horaires du restaurant {codeSap} - {store && store.nomWeb}</span>
            </div>
            <GldButton className={"w-full md:w-max"} icon={<RefreshIcon className={loaded ? "" : "rotate"}/>}
                       onClick={doRefresh}>Rafraîchir</GldButton>
            {!active.special && <GldButton className={"w-full md:w-max"} color={"green"} icon={savingIncon}
                                           onClick={doSave}>Sauvegarder</GldButton>}
        </div>
        <div className="grid grid-cols-12 align-middle gap-x-6">
            <div className="py-2 align-middle md:block hidden md:col-span-2 space-y-6 text-right border-r-2 pr-2">
                <div className={""}>
                    <h1 className={"uppercase text-xl"}>Horaires disponibles</h1>
                    <div className={""}>
                        {<SideBar schedules={schedules} active={active}
                                  displaySchedule={(is, indexSubSchedule, sp) => displaySchedule(is, indexSubSchedule, sp)}/>}
                    </div>
                    <div className={""}>
                        {<SpecialSideBar schedules={schedules} active={active}
                                         displaySchedule={(is, indexSubSchedule, sp) => displaySchedule(is, indexSubSchedule, sp)}/>}
                    </div>
                </div>
                <button className={"md:hidden inline-block justify-end"} onClick={() => setOpen(true)}>
                    <div className="w-6 h-6"><MenuIcon/></div>
                </button>
            </div>
            {active.special ? <div className={"col-span-10"}>
                <div className={""}>
                    <p className={"uppercase text-xl"}>
                        Horaires exceptionnels
                    </p>
                </div>
                <div className="py-2 inline-block ">
                    <div
                        className="hidden md:block shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg mt-6">
                        <table className="min-w-full divide-y divide-gray-200">
                            <thead className={"bg-gray-50"}>
                            <tr>
                                <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
                                    Cause
                                </th>
                                <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
                                    Type
                                </th>
                                <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
                                    Date début
                                </th>
                                <th className={"px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"}>
                                    Date fin
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {specialSchedules.map((s, i) => (<tr key={s["exploitationEvenementId"]}>
                                <EditableSpecialSchedule index={i} schedule={s}
                                    // onChange={onSpecialScheduleChange}
                                                         config={specialSchedulesConfig}/>
                            </tr>))}
                            </tbody>
                        </table>

                    </div>
                    <div className={"py-4 text-right gap-x-6"}>
                        <GldButton
                            onClick={() => {
                                setSpecialSchedules([...specialSchedules, {
                                    "exploitationId": store.exploitationId,
                                    "cause": `${specialSchedulesConfig.causes[0].key}`,
                                    "type": `${specialSchedulesConfig.types[0].key}`,
                                    "dateDebut": "",
                                    "dateFin": "",
                                    "jourEntier": true,
                                    "heureDebut": "00:00",
                                    "heureFin": "00:00"
                                }])
                            }}>Ajouter</GldButton>
                        <GldButton color={"green"} className={"ml-6"}
                                   onClick={doSaveSpecialSchedules}>Sauvegarder</GldButton>
                    </div>
                    {process.env.NODE_ENV !== "production" && <div className={""}>
                        <p className={"break-all"}>{JSON.stringify(specialSchedules)}</p>
                        <p className={"break-all"}>{JSON.stringify(specialSchedulesConfig)}</p>
                    </div>}
                </div>
            </div> : <div className="py-2 inline-block md:col-span-10 w-full">
                <div className={"flex"}>
                    <p className={"flex-grow uppercase text-xl"}>
                        Horaires {(currentSchedule && currentSchedule.type) ? currentSchedule.type :
                        <span className={"rounded w-20 h-4 bg-gray-500 animate-pulse inline-block"}/>}
                    </p>
                    <button className={"md:hidden inline-block justify-end"} onClick={() => setOpen(true)}>
                        <div className="w-6 h-6"><MenuIcon/></div>
                    </button>
                </div>
                <div
                    className="hidden md:block shadow overflow-x-auto border-b border-gray-200 sm:rounded-lg mt-6">
                    <table className="min-w-full divide-y divide-gray-200">
                        <thead className="bg-gray-50">
                        <tr>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Jour de la semaine
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Créneau matin début
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Créneau matin fin
                            </th>

                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Créneau après-midi début
                            </th>

                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Créneau après-midi fin
                            </th>
                            <th scope="col"
                                className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                Fermé
                            </th>
                        </tr>
                        </thead>
                        <tbody className={" divide-y divide-gray-200"}>
                        {days.map(d => <EditableSchedule key={d}
                                                         day={d}
                                                         errors={currentErrors}/>)}
                        </tbody>
                    </table>

                </div>
                <div className={"md:hidden grid grid-cols-1 gap-y-10"}>
                    <Accordion allowZeroExpanded>
                        {currentSchedule && days.map(d => (<AccordionItem
                            key={d}
                            className={"my-3 border-gray-100"}
                            activeClassName={""}
                        >
                            <AccordionItemHeading
                                className={"schedule-day items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"}
                            ><AccordionItemButton>
                                {d}
                            </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                                <AccordionSchedule key={d} day={d}
                                                   schedule={currentSchedule}
                                />
                            </AccordionItemPanel>
                        </AccordionItem>))}
                    </Accordion>
                </div>
            </div>}


        </div>
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden" onClose={setOpen}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>
                    <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-screen max-w-md">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 pt-4 pr-2 flex sm:-ml-10 sm:pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => setOpen(false)}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-full flex flex-col py-6 bg-white shadow-xl overflow-y-scroll">
                                    <div className="px-4 sm:px-6">
                                        <Dialog.Title className="text-lg font-medium text-gray-900">Horaires
                                            disponibles</Dialog.Title>
                                    </div>
                                    <div className="mt-6 relative flex-1 px-4 sm:px-6">
                                        <ul className={"flex-1"}>
                                            {<SideBar schedules={schedules} active={active}
                                                      displaySchedule={(is, indexSubSchedule, sp) => displaySchedule(is, indexSubSchedule, sp)}/>}
                                        </ul>
                                        <ul className={"flex-1"}>
                                            {<SpecialSideBar schedules={schedules} active={active}
                                                             displaySchedule={(is, indexSubSchedule, sp) => displaySchedule(is, indexSubSchedule, sp)}/>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    </div>)
}