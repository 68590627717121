import {useEffect, useState} from "react";

export default function SpecialTextInput({field, text, onChange, readOnly, className, isError, options}) {
    const [currentText, setCurrentText] = useState(text)
    useEffect(() => setCurrentText(text), [text])

    return (<>
            <select className={"w-full"} onChange={(event => {
                setCurrentText(event.target.value)
                onChange(field, event.target.value)
            })} value={currentText}>
                {options.map(o => (
                    <option key={o.key} value={o.key}>{o.value}</option>
                ))}
            </select>
        </>
    )
}