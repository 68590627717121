import {useEffect, useState} from "react";
import SCTimePicker from "./SCTimePicker";


export default function AccordionSchedule({schedule, day, onChange}) {

    let [currentSchedule, setCurrentSchedule] = useState(schedule)

    useEffect(() => setCurrentSchedule(schedule), [schedule])

    return (
        <div className={"grid grid-cols-1 mt-3 "}>

            <div className={"flex flex-col gap-y-2"}>
                <div
                    className="border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline w-full">
                    <label className={"flex item-center space-x-3"}> Fermé &nbsp;
                        <input
                            className="form-tick h-6 w-6 border border-gray-300 rounded-md checked:bg-blue-600 checked:border-transparent focus:outline-none"
                            type="checkbox"
                            checked={currentSchedule[`estFerme${day}`]}
                            onChange={e => {
                                setCurrentSchedule(prevState => {
                                    prevState[`estFerme${day}`] = e.target.checked
                                    return ({...prevState})
                                })
                                onChange(`estFerme${day}`, e.target.checked)
                            }}
                        />
                    </label>
                </div>
                <SCTimePicker field={`heureDebutPeriode1`}
                              day={day}/>
                <SCTimePicker field={`heureFinPeriode1`}
                              day={day}/>
                <SCTimePicker field={`heureDebutPeriode2`}
                              day={day}/>
                <SCTimePicker field={`heureFinPeriode2`}
                              day={day}/>
            </div>
        </div>
    )
}