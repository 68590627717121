import {useCallback} from "react";

export function timeToNumber(s) {
    return s.split(":").join("")
}

export function dateToNumber(date) {
    return date.split("-").join("")
}


export const useDateToNumber = () => {
    return useCallback((d) => dateToNumber(d), [])
}

export const useTimeToNumber = () => {
    return useCallback((s) => timeToNumber(s), [])
}

export const useIsvalidTime = () => {
    return useCallback((s) => s && s.split(":").length === 2 && s.split(":")[1] % 15 === 0, [])
}
