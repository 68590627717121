import {createSlice} from '@reduxjs/toolkit'

const defaultHours = [
    "--",
    "00",
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",]
const defaultMinutes = ["--", "00", "15", "30", "45"]

const FIRST_SLOT = "heureDebutPeriode1"
const SECOND_SLOT = "heureFinPeriode1"
const THIRD_SLOT = "heureDebutPeriode2"
const FOURTH_SLOT = "heureFinPeriode2"

function getHoursMinutes(timeAsString) {
    if (timeAsString === "") {
        return {hours: null, minutes: null}
    } else {
        return {hours: parseInt(timeAsString.split(":")[0]), minutes: parseInt(timeAsString.split(":")[1])}
    }
}

const DEFAULT_STATE = {
    "Lundi": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }, "Mardi": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }, "Mercredi": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }, "Jeudi": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }, "Vendredi": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }, "Samedi": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }, "Dimanche": {
        "heureDebutPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode1": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureDebutPeriode2": {"hours": defaultHours, "minutes": defaultMinutes},
        "heureFinPeriode2": {"hours": defaultHours, "minutes": defaultMinutes}
    }
};
export const possibleTimesSlice = createSlice({
    name: 'possibleTimes', initialState: {
        value: DEFAULT_STATE,
    }, reducers: {
        onScheduleChange: (state, action) => {
            let slot = action.payload.field
            let day = action.payload.day
            let currentSchedule = action.payload.schedule
            let time = currentSchedule[`${slot}${day}`]
            let hours
            let minutes
            let hoursMinutes = getHoursMinutes(time)
            if (slot === FIRST_SLOT) {
                //    modifier heures SECOND_SLOT
                hours = defaultHours
                minutes = defaultMinutes
                let nexHourMinutes = getHoursMinutes(currentSchedule[`${SECOND_SLOT}${day}`])
                let other = getHoursMinutes(currentSchedule[`${THIRD_SLOT}${day}`])
                if (hoursMinutes.hours !== null && hoursMinutes.minutes !== null) {
                    hours = hours.filter(h => (parseInt(h) >= hoursMinutes.hours) || h === "--")
                    if (other.hours !== null && other.minutes != null) {
                        hours = hours.filter(h => parseInt(h) <= other.hours || h === "--")
                    }
                    if (hoursMinutes.hours === nexHourMinutes.hours) {
                        minutes = minutes.filter(m => parseInt(m) >= hoursMinutes.minutes || m === "--")
                    } else {
                        state.value[day][FIRST_SLOT] = {...state.value[day][FIRST_SLOT], minutes: defaultMinutes}
                    }
                }

                state.value[day][SECOND_SLOT] = {
                    "hours": hours, "minutes": minutes
                }
            } else if (slot === SECOND_SLOT) {
                hours = defaultHours
                minutes = defaultMinutes
                //    modifier heures FIRST_SLOT
                let previousHM = getHoursMinutes(currentSchedule[`${FIRST_SLOT}${day}`])
                if (hoursMinutes.hours !== null && hoursMinutes.minutes !== null) {
                    hours = hours.filter(h => h === "--" || parseInt(h) <= hoursMinutes.hours)
                    if (hoursMinutes.hours === previousHM.hours) {
                        minutes = minutes.filter(m => parseInt(m) <= hoursMinutes.minutes || m === "--")
                    }
                }
                state.value[day][FIRST_SLOT] = {
                    "hours": hours, "minutes": minutes
                }

                //    modifier heures heureDebutPeriode2
                hours = defaultHours
                minutes = defaultMinutes
                let nextHM = getHoursMinutes(currentSchedule[`${THIRD_SLOT}${day}`])
                let other = getHoursMinutes(currentSchedule[`${FOURTH_SLOT}${day}`])
                if (hoursMinutes.hours !== null && hoursMinutes.minutes !== null) {
                    hours = hours.filter(h => (parseInt(h) >= hoursMinutes.hours) || h === "--")
                    if (other.hours !== null && other.minutes != null) {
                        hours = hours.filter(h => parseInt(h) <= other.hours || h === "--")
                    }
                    if (hoursMinutes.hours === nextHM.hours) {
                        minutes = minutes.filter(m => parseInt(m) >= hoursMinutes.minutes || m === "--")
                    } else {
                        state.value[day][SECOND_SLOT] = {...state.value[day][SECOND_SLOT], minutes: defaultMinutes}
                    }
                }
                state.value[day][THIRD_SLOT] = {
                    "hours": hours, "minutes": minutes
                }

            } else if (slot === THIRD_SLOT) {
                hours = defaultHours
                minutes = defaultMinutes
                let previousHM = getHoursMinutes(currentSchedule[`${SECOND_SLOT}${day}`])
                let other = getHoursMinutes(currentSchedule[`${FIRST_SLOT}${day}`])
                if (hoursMinutes.hours !== null && hoursMinutes.minutes !== null) {
                    hours = hours.filter(h => (parseInt(h) <= hoursMinutes.hours) || h === "--")
                    if (other.hours !== null && other.minutes != null) {
                        hours = hours.filter(h => parseInt(h) >= other.hours || h === "--")
                    }
                    if (hoursMinutes.hours === previousHM.hours) {
                        minutes = minutes.filter(m => parseInt(m) >= hoursMinutes.minutes || m === "--")
                    } else {
                        state.value[day][THIRD_SLOT] = {...state.value[day][THIRD_SLOT], minutes: defaultMinutes}
                    }
                }
                state.value[day][SECOND_SLOT] = {
                    "hours": hours, "minutes": minutes
                }
                //    modifier heures FOURTH_SLOT
                hours = defaultHours
                minutes = defaultMinutes
                let nextHM = getHoursMinutes(currentSchedule[`${FOURTH_SLOT}${day}`])
                if (hoursMinutes.hours !== null && hoursMinutes.minutes !== null) {
                    hours = hours.filter(h => h === "--" || parseInt(h) >= hoursMinutes.hours)
                    if (hoursMinutes.hours === nextHM.hours) {
                        minutes = minutes.filter(m => parseInt(m) >= hoursMinutes.minutes || m === "--")
                    }
                }
                state.value[day][FOURTH_SLOT] = {
                    "hours": hours, "minutes": minutes
                }
            } else if (slot === FOURTH_SLOT) {
                //    modifier heures heureDebutPeriode2
                hours = defaultHours
                minutes = defaultMinutes
                let previousHM = getHoursMinutes(currentSchedule[`${THIRD_SLOT}${day}`])
                let other = getHoursMinutes(currentSchedule[`${SECOND_SLOT}${day}`])
                if (hoursMinutes.hours !== null && hoursMinutes.minutes !== null) {
                    hours = hours.filter(h => (parseInt(h) <= hoursMinutes.hours) || h === "--")
                    if (other.hours !== null && other.minutes != null) {
                        hours = hours.filter(h => parseInt(h) >= other.hours || h === "--")
                    }
                    if (hoursMinutes.hours === previousHM.hours) {
                        minutes = minutes.filter(m => parseInt(m) <= hoursMinutes.minutes || m === "--")
                    } else {
                        state.value[day][FOURTH_SLOT] = {...state.value[day][FOURTH_SLOT], minutes: defaultMinutes}
                    }
                }
                state.value[day][THIRD_SLOT] = {
                    "hours": hours, "minutes": minutes
                }
            }

        },
    },
})


export default possibleTimesSlice.reducer