import {useEffect, useState} from "react";

export default function SpecialDatePicker({field, date, onChange, readOnly, className, isError}) {
    const [currentTime, setCurrentTime] = useState(date)

    useEffect(() => setCurrentTime(date), [date])

    const inputClassName = `appearance-none rounded-sm w-full py-1 px-3 focus:outline-none focus:border-gray-400 focus:shadow-outline ${readOnly ? "text-gray-500 border border-gray-200 bg-gray-200" : isError ? "bg-red-500" : "bg-white border-gray-100 border text-gray-700"} ${className}`

    return (<div>
        <input className={inputClassName} value={currentTime}
               onChange={(e) => {
                   setCurrentTime(e.target.value)
                   onChange(field, e.target.value)
               }} type="date"
               readOnly={readOnly}/>

    </div>)
}