import {configureStore} from '@reduxjs/toolkit'
import schedulesReducer from "./schedulesSlice";
import currentScheduleSlice from "./currentScheduleSlice";
import possibleTimesSlice from "./possibleTimes";

export default configureStore({
    reducer: {
        schedules: schedulesReducer,
        currentSchedule: currentScheduleSlice,
        possibleTimes: possibleTimesSlice
    },
})