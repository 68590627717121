export default function SideBar({schedules, displaySchedule, active}) {
    let isActive = (schedule, subSchedule) => !active.special && (active.indexSchedule === schedule && active.indexSubSchedule === subSchedule)
    return (schedules.length !== 0 ? schedules.map((schedule, iSchedule) => (
            <div className={"mr-2"}
                 key={`${schedule.name}`}>
                <div className={"w-max p-2 grid grid-cold-1 gap-x-2"}>
                    <div className={"border-b-2 w-max"}>
                        {schedule.name.substring(13)}</div>
                </div>
                {schedule.subSchedules && schedule.subSchedules.map((subSchedule, iSubSchedule) => {
                    return (
                        <button key={`subs:${iSchedule}:${iSubSchedule}`}
                                className={`rounded text-right w-full py-1 px-2 my-1 ${isActive(iSchedule, iSubSchedule) ? 'text-red-700 border-red-600  border-l-8  bg-red-100' : 'border border-gray-100'}`}
                                onClick={() => {
                                    displaySchedule(iSchedule, iSubSchedule, false)
                                }}>
                            <div key={`${subSchedule.type}${subSchedule.description}`}>
                                {subSchedule.type}
                            </div>
                        </button>
                    )
                })
                }
            </div>)) :
        <div className={"mr-2"}>
            <div className={"w-max p-2 grid grid-cold-1 gap-x-2"}>
                <div className={"border-b-2 w-max"}>
                    <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-36 h-4 m-1"}/>
                </div>
            </div>
            <button
                className={`rounded-sm justify-end text-right w-full py-1 px-2 my-1 border border-gray-100`}>
                <div>
                    <div className={"animate-pulse rounded bg-gray-500 w-12 md:w-36 h-4 m-1"}/>
                </div>
            </button>
        </div>)

}