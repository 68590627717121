import {Link} from "react-router-dom";
import {useEffect, useState} from "react";
import {useAxios} from "../services/httpclient";
import GldButton from "./GldButton";
import {LogoutIcon} from "@heroicons/react/solid";
import {useKeycloak} from "@react-keycloak/web";

export default function GldHeader() {

    const {keycloak, initialized} = useKeycloak()
    useAxios();
    const [userInfo, setUserInfo] = useState()
    useEffect(() => {
        initialized && keycloak.loadUserInfo().then(
            res => {
                setUserInfo(res)
            })
    }, [keycloak, initialized])

    return (
        <div
            className="flex justify-between items-center border-b-2 border-gray-100 py-6 md:justify-start md:space-x-10">
            <div className="flex justify-start lg:w-0 lg:flex-1">
                <Link to="/">
                    <span className="sr-only">Workflow</span>
                    <img
                        className="h-8 w-auto sm:h-10"
                        src="https://upload.wikimedia.org/wikipedia/fr/7/71/Groupe-le-duff.png"
                        alt=""
                    />
                </Link>
            </div>

            <Link to="/stores" className="text-base font-medium text-gray-500 hover:text-gray-900">
                Restaurants
            </Link>
            <div className="md:flex items-center justify-end md:flex-1 lg:w-0">
            <span
                className="hidden md:block whitespace-nowrap text-base font-medium text-gray-500 hover:text-gray-900">
                {!!userInfo && userInfo.name}
            </span>
                &nbsp;
                {keycloak &&
                <GldButton  color={"red"} icon={<LogoutIcon/>} onClick={() => {
                    keycloak.logout();
                }}
                           className="whitespace-nowrap text-gray-500 hover:text-gray-900">
                </GldButton>}
            </div>
        </div>)
}