export default function GldButton({color, onClick, icon, children, className}) {
    if (!color) color = "blue"
    let classNames = `
        bg-${color}-100 
        hover:bg-${color}-200
        focus:outline-none
        focus:ring-2
        focus:ring-${color}-600
        focus:ring-opacity-50
        text-${color}-500
        md:px-4
        md:py-2
        px-2
        py-1
        rounded-md
        font-bold
        inline-flex
        items-center
        justify-center
        ${className}`

    return (
        <button
            onClick={onClick}
            className={classNames}>
            {icon && <div className="w-4 h-4">{icon}</div>}
            {icon && typeof children == "string" && <div className={"w-1 sm:w-2"}/>}
            {typeof children === "string" && <div>{children}</div>}
        </button>
    )
}