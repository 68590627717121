import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Shops from "./navigation/Shops";
import ShopSchedules from "./navigation/ShopSchedules";
import GldHeader from "./components/GldHeader";
import {hasRole} from "./components/ProtectedRoute";
import Err404 from "./navigation/Err404";
import Unauthorized from "./navigation/Unauthorized";
import {useKeycloak} from "@react-keycloak/web";
import {useEffect, useState} from "react";
import {ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'

export default function App() {
    const [authorized, setAuthorized] = useState(false)
    const [loading, setLoading] = useState(true)

    const {initialized, keycloak} = useKeycloak()

    useEffect(() => {
        if (initialized) {
            setLoading(false)
            if (keycloak && hasRole(['selfcare-user'], keycloak)) {
                setAuthorized(true)
            }
        }

    }, [keycloak, initialized])

    if (!initialized && !keycloak) {
        return (<h3>Loading...</h3>);
    }
    return (
        <div>{loading ? <div className={"container m-10"}>Chargement...</div> : (authorized ? <div>
            <ToastContainer/>
            <Router>
                <div className="md:container mx-2 md:mx-auto">
                    <GldHeader/>
                    {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
                    <div>
                        <Switch>
                            <Route exact path="/stores" component={Shops}/>
                            <Route exact path="/stores/:codeSap/schedule"
                                   component={ShopSchedules}/>
                            <Route exact path="/"><Redirect
                                to="/stores"/></Route>
                            <Route path="/*" component={Err404}/>
                        </Switch>
                    </div>
                </div>
            </Router>
        </div> : <Unauthorized/>)}
        </div>
    );
}
