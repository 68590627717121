import {useKeycloak} from "@react-keycloak/web";

export default function Unauthorized() {

    const {keycloak} = useKeycloak()

    return (
        <div className={"container m-10"}>
            <p>Vous n'avez pas les droits nécessaires pour utiliser cette application. Veuillez contacter un administrateur. </p>
            <p><button
                className={"font-medium border-b-2 border-gray-200100 hover:text-red-900"}
                onClick={() => {
                    keycloak.logout()
                }}>Se déconnecter.</button>
            </p>
        </div>
    )
}