import {createSlice} from '@reduxjs/toolkit'

/**
 *
 * @type {Slice<{value: null}, {setSchedule: reducers.setSchedule}, string>}
 * {
 *   "idRestaurant": "294001",
 *   "exploitationId": 507,
 *   "codeSAP": "H007",
 *   "nomWeb": "Rennes - C.C. Alma",
 *   "statut": "Ouvert",
 *   "typeExploitation": "Succursale",
 *   "infosHorairesDefaut": [
 *     {
 *       "id": 1762,
 *       "type": "Defaut",
 *       "description": "DA RENNES ALMA",
 *       "heureDebutPeriode1Lundi": "02:45",
 *       "heureFinPeriode1Lundi": "13:45",
 *       "heureDebutPeriode2Lundi": "18:45",
 *       "heureFinPeriode2Lundi": "23:45",
 *       "estFermeLundi": false,
 *       "heureDebutPeriode1Mardi": "11:45",
 *       "heureFinPeriode1Mardi": "14:30",
 *       "heureDebutPeriode2Mardi": "18:30",
 *       "heureFinPeriode2Mardi": "22:30",
 *       "estFermeMardi": false,
 *       "heureDebutPeriode1Mercredi": "11:30",
 *       "heureFinPeriode1Mercredi": "14:30",
 *       "heureDebutPeriode2Mercredi": "18:30",
 *       "heureFinPeriode2Mercredi": "22:30",
 *       "estFermeMercredi": false,
 *       "heureDebutPeriode1Jeudi": "11:45",
 *       "heureFinPeriode1Jeudi": "14:30",
 *       "heureDebutPeriode2Jeudi": "18:30",
 *       "heureFinPeriode2Jeudi": "22:30",
 *       "estFermeJeudi": false,
 *       "heureDebutPeriode1Vendredi": "11:30",
 *       "heureFinPeriode1Vendredi": "14:30",
 *       "heureDebutPeriode2Vendredi": "18:30",
 *       "heureFinPeriode2Vendredi": "23:45",
 *       "estFermeVendredi": false,
 *       "heureDebutPeriode1Samedi": "11:30",
 *       "heureFinPeriode1Samedi": "14:30",
 *       "heureDebutPeriode2Samedi": "18:30",
 *       "heureFinPeriode2Samedi": "23:45",
 *       "estFermeSamedi": false,
 *       "heureDebutPeriode1Dimanche": "11:30",
 *       "heureFinPeriode1Dimanche": "14:30",
 *       "heureDebutPeriode2Dimanche": "18:30",
 *       "heureFinPeriode2Dimanche": "",
 *       "estFermeDimanche": false
 *     }
 *   ],
 *   "infosHorairesExceptionnel": [
 *     {
 *       "exploitationId": 507,
 *       "exploitationEvenementId": 25,
 *       "cause": "Ferié",
 *       "type": "Fermeture",
 *       "dateDebut": "2022-01-01",
 *       "dateFin": "2022-01-03",
 *       "jourEntier": true,
 *       "heureDebut": "",
 *       "heureFin": ""
 *     },
 *     {
 *       "exploitationId": 507,
 *       "exploitationEvenementId": 26,
 *       "cause": "Ferié",
 *       "type": "Fermeture",
 *       "dateDebut": "2022-01-24",
 *       "dateFin": "2022-01-29",
 *       "jourEntier": true,
 *       "heureDebut": "00:00",
 *       "heureFin": "00:00"
 *     }
 *   ],
 *   "infosHorairesService": [
 *     {
 *       "id": 2360,
 *       "type": "Vente en ligne",
 *       "description": "DA RENNES ALMA  CLIC_COLLECT",
 *       "heureDebutPeriode1Lundi": "11:45",
 *       "heureFinPeriode1Lundi": "15:00",
 *       "heureDebutPeriode2Lundi": "18:30",
 *       "heureFinPeriode2Lundi": "22:00",
 *       "estFermeLundi": false,
 *       "heureDebutPeriode1Mardi": "11:30",
 *       "heureFinPeriode1Mardi": "14:30",
 *       "heureDebutPeriode2Mardi": "18:30",
 *       "heureFinPeriode2Mardi": "22:00",
 *       "estFermeMardi": false,
 *       "heureDebutPeriode1Mercredi": "11:30",
 *       "heureFinPeriode1Mercredi": "15:00",
 *       "heureDebutPeriode2Mercredi": "18:30",
 *       "heureFinPeriode2Mercredi": "22:00",
 *       "estFermeMercredi": false,
 *       "heureDebutPeriode1Jeudi": "11:30",
 *       "heureFinPeriode1Jeudi": "15:00",
 *       "heureDebutPeriode2Jeudi": "18:30",
 *       "heureFinPeriode2Jeudi": "22:00",
 *       "estFermeJeudi": false,
 *       "heureDebutPeriode1Vendredi": "11:30",
 *       "heureFinPeriode1Vendredi": "15:00",
 *       "heureDebutPeriode2Vendredi": "18:30",
 *       "heureFinPeriode2Vendredi": "22:00",
 *       "estFermeVendredi": false,
 *       "heureDebutPeriode1Samedi": "11:30",
 *       "heureFinPeriode1Samedi": "14:30",
 *       "heureDebutPeriode2Samedi": "18:30",
 *       "heureFinPeriode2Samedi": "22:00",
 *       "estFermeSamedi": false,
 *       "heureDebutPeriode1Dimanche": "11:30",
 *       "heureFinPeriode1Dimanche": "14:30",
 *       "heureDebutPeriode2Dimanche": "18:30",
 *       "heureFinPeriode2Dimanche": "22:00",
 *       "estFermeDimanche": false
 *     },
 *     {
 *       "id": 2928,
 *       "type": "Livraison a domicile",
 *       "description": "DA RENNES ALMA  LIVRAISON",
 *       "heureDebutPeriode1Lundi": "11:45",
 *       "heureFinPeriode1Lundi": "14:30",
 *       "heureDebutPeriode2Lundi": "18:30",
 *       "heureFinPeriode2Lundi": "22:00",
 *       "estFermeLundi": false,
 *       "heureDebutPeriode1Mardi": "11:45",
 *       "heureFinPeriode1Mardi": "15:00",
 *       "heureDebutPeriode2Mardi": "19:00",
 *       "heureFinPeriode2Mardi": "22:00",
 *       "estFermeMardi": false,
 *       "heureDebutPeriode1Mercredi": "11:30",
 *       "heureFinPeriode1Mercredi": "14:30",
 *       "heureDebutPeriode2Mercredi": "18:30",
 *       "heureFinPeriode2Mercredi": "22:00",
 *       "estFermeMercredi": false,
 *       "heureDebutPeriode1Jeudi": "11:30",
 *       "heureFinPeriode1Jeudi": "14:30",
 *       "heureDebutPeriode2Jeudi": "18:30",
 *       "heureFinPeriode2Jeudi": "22:00",
 *       "estFermeJeudi": false,
 *       "heureDebutPeriode1Vendredi": "11:30",
 *       "heureFinPeriode1Vendredi": "14:30",
 *       "heureDebutPeriode2Vendredi": "18:30",
 *       "heureFinPeriode2Vendredi": "22:00",
 *       "estFermeVendredi": false,
 *       "heureDebutPeriode1Samedi": "11:30",
 *       "heureFinPeriode1Samedi": "14:30",
 *       "heureDebutPeriode2Samedi": "18:30",
 *       "heureFinPeriode2Samedi": "22:00",
 *       "estFermeSamedi": false,
 *       "heureDebutPeriode1Dimanche": "11:30",
 *       "heureFinPeriode1Dimanche": "14:30",
 *       "heureDebutPeriode2Dimanche": "18:30",
 *       "heureFinPeriode2Dimanche": "22:00",
 *       "estFermeDimanche": false
 *     }
 *   ]
 * }
 */

export const currentScheduleSlice = createSlice({
    name: 'currentSchedule',
    initialState: {
        value: null,
    },
    reducers: {
        setSchedule: (state, action) => {
            state.value = action.payload
        },
    }
})

// Action creators are generated for each case reducer function
export const {setSchedule} = currentScheduleSlice.actions

export default currentScheduleSlice.reducer