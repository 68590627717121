import {useEffect, useState} from "react";
import './SCTimePicker.css'
import {useDispatch, useSelector} from "react-redux";

export default function SCTimePicker({field, day}) {


    const currentSchedule = useSelector(s => s.currentSchedule.value)


    const [time, setTime] = useState(null)
    const possibleTimes = useSelector(s => s.possibleTimes.value)
    const [possibleHours, setPossibleHours] = useState([])
    const [possibleMinutes, setPossibleMinutes] = useState([])
    const dispatch = useDispatch()


    useEffect(() => {
        if (currentSchedule && possibleTimes[day] && possibleTimes[day][field]) {
            setTime(currentSchedule[`${field}${day}`])
            setPossibleHours(possibleTimes[day][field]["hours"])
            setPossibleMinutes(possibleTimes[day][field]["minutes"])
        }
    }, [currentSchedule, field, day, possibleTimes])


    function getHours() {
        return time && time !== "" ? time.split(":")[0] : "--"
    }

    function getMinutes() {
        return time && time !== "" ? time.split(":")[1] : "--"
    }

    function onTimeChange(string) {
        setTime(string)
        let newTime
        if (time !== "" && string.includes("--")) {
            setTime("")
            newTime = ""
        } else {
            newTime = string
        }
        let schedule = {...currentSchedule}
        schedule[`${field}${day}`] = newTime
        dispatch({type: "currentSchedule/setSchedule", payload: schedule})
        dispatch({
            type: "possibleTimes/onScheduleChange",
            payload: {
                day,
                field,
                schedule
            }
        })
    }


    return (<div>
        <>
            <select className={"w-auto border-solid border-gray-300"} disabled={currentSchedule[`estFerme${day}`]}
                    value={getHours()}
                    onChange={(e) => {
                        onTimeChange(`${e.target.value}:${getMinutes()}`)
                    }}
            >
                {possibleHours.map(h => <option key={Math.random()} value={h}>{h}</option>)}
            </select>
            :
            < select disabled={currentSchedule[`estFerme${day}`]} value={getMinutes()}
                     onChange={(e) => {
                         onTimeChange(`${getHours()}:${e.target.value}`)
                     }}
            >
                {possibleMinutes.map(m => <option key={Math.random()} value={m}>{m}</option>)}
            </select> </>
    </div>)
}