export default function SpecialSideBar({active, displaySchedule}) {

    return (
        <div className={"mr-2"}>
            <div className={"w-max p-2 grid grid-cold-1 gap-x-2"}>
                <div className={"border-b-2 w-max"}>
                    Horaires exceptionnels
                </div>
            </div>
            <button key={`subs:special`}
                    className={`rounded text-right w-full py-1 px-2 my-1 ${active.special ? 'text-red-700 border-red-600  border-l-8  bg-red-100' : 'border border-gray-100'}`}
                    onClick={() => {
                        displaySchedule(0, 0, true)
                    }}>
                <div key={`special`}>
                    Afficher
                </div>
            </button>
        </div>
    )
}