import {createSlice} from '@reduxjs/toolkit'

export const schedulesSlice = createSlice({
    name: 'schedules',
    initialState: {
        value: [],
    }, reducers: {
        deleteSchedules: (state) => {
            state.value = []
        },
        addSchedule: (state, action) => {
            state.value = [...state.value, action.payload]
        },
        updateCurrentSchedule: (state, action) => {
            state.value[action.payload.active.indexSchedule].subSchedules[action.payload.active.indexSubSchedule] = action.payload.currentSchedule
        }
    },
})

// Action creators are generated for each case reducer function

export default schedulesSlice.reducer