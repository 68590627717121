import Keycloak from 'keycloak-js'

export const keycloak = Keycloak({
        url: process.env.REACT_APP_KEYCLOAK_URL,
        clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
        realm: process.env.REACT_APP_KEYCLOAK_REALM,
    }
)

export const initOptions = {
    onLoad: 'login-required',
}
